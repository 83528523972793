/* eslint-disable */
import { Box, Typography, useTheme } from '@mui/material';
import { MDXProvider } from '@mdx-js/react';
import { graphql, navigate } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Trans } from 'react-i18next';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, { useContext } from 'react';

import ProgrammingAssignmentPool from '../components/assignment/programming/programming-assignment-pool';
import Button from '../components/button';
import MoreInfo from '../components/more-info';
import { postAnalyticsData } from '../http-actions/analytics-actions';
import { AuthContext } from '../providers/auth-provider';
import { getModuleProgressPercentage, moduleHasPoints } from '../util/point-utils';

import { useStore } from '@nanostores/react'
import { points } from '../stores/points';

/*
TODO:
REFACTOR CSS
*/

const sxStyles = {
    root: (theme) => ({
        display: 'flex',
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
            flexDirection: 'column',
        },
        justifyContent: 'space-between',
    }),
    headerContainer: (theme) => ({
        display: 'flex',
        flexDirection: 'column',
        marginRight: theme.spacing(2),
    }),
    title: {
        lineHeight: 0.9,
        fontSize: {
            xs: `${50 / 16}rem`,
            sm: `${100 / 16}rem`,
        },
        maxWidth: {
            xs: 280,
            sm: 500,
        },
    },
    progressHeader: {
        fontSize: `${24 / 16}rem`,
        fontFamily: '"Teko", "Helvetica", sans-serif',
        fontWeight: 400,
    },
    cta: (theme) => ({
        marginBottom: theme.spacing(4),
        maxWidth: 500,
    }),
    workload: (theme) => ({
        marginBottom: theme.spacing(4),
        fontSize: `${16 / 16}rem`,
        color: 'rgba(0, 0, 0, 0.54)',
    }),
    buttonContainer: (theme) => ({
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: theme.spacing(4),
    }),
};

const shortcodes = {
    ProgrammingAssignmentPool,
    MoreInfo,
};

String.prototype.nthIndexOf = function(pattern, n) {
    var i = -1;

    while (n-- && i++ < this.length) {
        i = this.indexOf(pattern, i);
        if (i < 0) break;
    }

    return i;
};

export default function PageTemplate({ data, ...props }) {
    let coursePath = "";
    if (typeof window !== 'undefined') {
        coursePath = window.location.pathname;
        const secondIndex = coursePath.nthIndexOf("/", 2);
        if (secondIndex !== -1) {
            coursePath = coursePath.substring(0, secondIndex);
        }        
    }

    if (coursePath !== "" && data.allMdx && data.allMdx.edges) {
        data.allMdx.edges = data.allMdx.edges.filter((edge) => {
            return edge?.node?.fields?.slug.startsWith(coursePath);
        });
    }

    let isExperiments = false;
    if (typeof window != "undefined" && window && window.location) {
        isExperiments = window?.location?.pathname?.includes('/experiments');   
    }

    const urlForFirstPart = data.allMdx?.edges[0]?.node.fields.slug;
    console.log("The slug!");
    console.log(urlForFirstPart);

    const { state: authState } = useContext(AuthContext);

    const logPageNavigation = async (path) => {
        console.log('Log page navigation to address ' + path);
        postAnalyticsData(authState.token, 'navigation', {
            navigate_to: path,
            type: 'course-main-page-navigation',
        });
    };

    const moduleCollectionPath = coursePath.replace(/^\/|\/$/g, '');

    const pointStore = useStore(points);

    const moduleName = data.allMdx?.edges[0]?.node.fields.category;

    let hasPoints = moduleHasPoints(pointStore, moduleCollectionPath, moduleName);

    if (isExperiments) {
        hasPoints = false;
    }

    const moduleProgressPercentage = getModuleProgressPercentage(pointStore, moduleCollectionPath, moduleName);
    
    const theme = useTheme();
    let progressNumberColor;
    if (moduleProgressPercentage === 100) {
        progressNumberColor = { color: theme.palette.success.main };
    } else if (0 < moduleProgressPercentage && moduleProgressPercentage < 100) {
        progressNumberColor = { color: theme.palette.error.main };
    } else {
        progressNumberColor = { color: theme.palette.common.black };
    }

    return (
        <>
            <Box component={'main'} id="skip-nav-target" sx={sxStyles.root}>
                <Box component={'div'} sx={sxStyles.headerContainer}>
                    <Typography sx={sxStyles.title} variant="h1">
                        {data.mdx.frontmatter.title}
                    </Typography>
                    <Typography sx={sxStyles.workload}>{data.mdx.frontmatter.workload}</Typography>
                    {data.mdx.frontmatter.metatext && (
                        <Typography sx={sxStyles.cta} variant="body1">
                            {data.mdx.frontmatter.metatext}
                        </Typography>
                    )}
                    <Box component={'div'} sx={sxStyles.buttonContainer}>
                        <Button
                            kind="fitech"
                            color="inherit"
                            sx={{
                                color: 'fitech.contrastText',
                                backgroundColor: 'fitech.main',
                                marginBottom: (theme) => theme.spacing(1),
                                transition: (theme) =>
                                    theme.transitions.create(['color', 'background-color']),
                                '&:hover': {
                                    color: 'common.black',
                                },
                                fontSize: {
                                    xs: `${30 / 16}rem`,
                                    md: `${36 / 16}rem`,
                                },
                            }}
                            onClick={() => {
                                logPageNavigation(urlForFirstPart);
                                navigate(urlForFirstPart);
                            }}
                        >
                            <Trans i18nKey="toFirstPart" />
                        </Button>
                        <Button
                            elevation={0}
                            kind="fitech"
                            sx={{
                                color: 'fitech.contrastText',
                                backgroundColor: 'rgba(0, 0, 0, 0.12)',
                                marginBottom: (theme) => theme.spacing(1),
                                transition: (theme) =>
                                    theme.transitions.create(['color', 'background-color']),
                                '&:hover': {
                                    color: 'common.black',
                                },
                                fontSize: {
                                    xs: `${30 / 16}rem`,
                                    md: `${36 / 16}rem`,
                                },
                                display: {
                                    md: 'none',
                                },
                            }}
                            color="inherit"
                            onClick={() => scrollTo('#course-navigation')}
                        >
                            <Trans i18nKey="courseNavigation" />
                        </Button>
                    </Box>
                </Box>
                <div style={{ display: !hasPoints ? 'none': 'flex', flexDirection: 'column' }}>
                    <Typography sx={sxStyles.progressHeader}>
                        <Trans i18nKey="proportionOfAssignmentsCompleted" />:
                    </Typography>
                    <span style={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                fontFamily: '"Teko", "Helvetica", sans-serif',
                                fontWeight: 400,
                                fontSize: {
                                    xs: `${90 / 16}rem`,
                                    md: `${180 / 16}rem`,
                                },
                                lineHeight: 1,
                                ...progressNumberColor,
                            }}
                        >
                            {isNaN(moduleProgressPercentage) ? 0 : moduleProgressPercentage}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: '"Teko", "Helvetica", sans-serif',
                                fontWeight: 400,
                                fontSize: {
                                    xs: `${45 / 16}rem`,
                                    md: `${90 / 16}rem`,
                                },
                                lineHeight: 1.25,
                                ...progressNumberColor,
                            }}
                        >
                            %
                        </Typography>
                    </span>
                </div>
            </Box>

            <MDXProvider components={shortcodes}>
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </MDXProvider>
        </>
    );
}

export const pageQuery = graphql`
    query IndexContentPageQuery($id: String, $category: String) {
        mdx(id: { eq: $id }) {
            id
            body
            fields {
                category
                slug
            }
            frontmatter {
                title
                published
                metatext
                workload
            }
        }
        allMdx(
            filter: {
                fields: { category: { eq: $category }, pageType: { eq: "material-content-page" } }
            }
            sort: { fields: [frontmatter___order], order: ASC }
        ) {
            edges {
                node {
                    id
                    fields {
                        category
                        slug
                    }
                    frontmatter {
                        title
                        published
                    }
                }
            }
        }
    }
`;
