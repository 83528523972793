import { Box, Typography } from '@mui/material';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

import Button from './button';

const expandIconStyle = {
    color: 'rgba(0, 0, 0, 0.54)',
};

export default function MoreInfo({ id = 'main', title, children, toggle = false }) {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    const info = t('checkAbilityToGetCredits');

    return (
        <Box
            component={'section'}
            id={`more-info-${id}`}
            sx={{
                backgroundColor: 'primary.bg',
            }}
        >
            <section
                style={{
                    backgroundColor: 'inherit',
                    margin: '0 -600rem',
                    padding: '0 600rem',
                }}
            >
                {!toggle ? (
                    <Box paddingY={4}>
                        {title && <Typography variant="h2">{title}</Typography>}
                        {children}
                        <i>{info}</i>
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="column">
                        <Button
                            color="inherit"
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: (theme) => theme.spacing(1),
                                textTransform: 'none',
                            }}
                            onClick={() => setShow(!show)}
                        >
                            {title && <Typography variant="h2">{title}</Typography>}
                            {show ? (
                                <ExpandLessIcon style={expandIconStyle} fontSize="large" />
                            ) : (
                                <ExpandMoreIcon style={expandIconStyle} fontSize="large" />
                            )}
                        </Button>
                        {show && (
                            <Typography
                                sx={(theme) => ({
                                    marginBottom: theme.spacing(2),
                                    padding: theme.spacing(0, 1),
                                    display: 'flex',
                                    flexDirection: 'column',
                                })}
                            >
                                {children}
                                <i>{info}</i>
                            </Typography>
                        )}
                    </Box>
                )}
            </section>
        </Box>
    );
}
